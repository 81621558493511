import React from 'react'

import SocialNetwork from 'components/SocialNetwork'
import { translate } from 'react-i18next'

type simpleFooterProps = {
  t: (value: string) => string;
}

const SimpleFooter = ({ t }: simpleFooterProps) => (
  <footer className='simple-menu--footer pb-5'>
    <div className='container'>
      <div className='row'>
        <div className='col-12 text-center'>
          <p className='fs-17 my-1 text-grayscale--500'>{t('social_helper')}</p>
          <SocialNetwork />
        </div>
      </div>
    </div>
  </footer>
)

export default translate('404')(SimpleFooter)
